import { CaretDownOutlined, CloseCircleFilled } from '@ant-design/icons';
import { Select } from 'antd';
import cn from 'classnames';
import { useRef, useState } from 'react';
import { styled } from 'styled-components';

import type { RefSelectProps } from 'antd/lib';
import type { SelectProps } from 'antd/lib';

const AppSelectContainer = styled.div`
  &.anticon {
    font-size: 14px;
  }

  &.app-select--clearable {
    position: relative;

    .clear-icon {
      display: none;
      position: absolute;
      right: 32px;
      top: 50%;
      margin-top: -7px;
      color: rgba(0, 0, 0, 0.25);
      font-size: 14px;
    }

    &:hover {
      .clear-icon {
        display: block;
      }
    }

    .ant-select-single.ant-select-show-arrow .ant-select-selection-item {
      padding-inline-end: 40px;
    }
  }
`;

export const AppSelect = ({ allowClear, open, ...props }: SelectProps) => {
  const selectWrapperNode = useRef<HTMLDivElement | null>(null);
  const selectComponentNode = useRef<RefSelectProps | null>(null);
  const selectInputNode = selectWrapperNode.current?.querySelector('input');
  const [isOpenLocal, setIsOpenLocal] = useState(open);
  const toggleDropdownVisibility = () => {
    if (open === undefined) {
      setIsOpenLocal(!isOpenLocal);
      if (
        !isOpenLocal &&
        selectWrapperNode.current &&
        selectComponentNode.current &&
        document.activeElement !== selectInputNode
      ) {
        selectComponentNode.current.focus();
      }
    }
  };

  const hideDropdown = () => {
    if (open === undefined) {
      setIsOpenLocal(false);
    }
  };

  const onClear = () => {
    props.onChange &&
      props.onChange(
        props.mode === 'multiple' || props.mode === 'tags' ? [] : undefined,
        {
          label: undefined,
        },
      );
  };

  const isSelected =
    props.mode === 'multiple' || props.mode === 'tags'
      ? props.value.length > 0
      : props.value !== null && props.value !== undefined;
  return (
    <AppSelectContainer
      ref={selectWrapperNode}
      className={cn('app-select', {
        'app-select--clearable': allowClear,
      })}
    >
      <Select
        ref={selectComponentNode}
        {...props}
        suffixIcon={
          <CaretDownOutlined
            onClick={() => {
              toggleDropdownVisibility();
            }}
          />
        }
        open={isOpenLocal}
        onClick={() => {
          toggleDropdownVisibility();
        }}
        onBlur={() => hideDropdown()}
        dropdownRender={(menu) => (
          <div
            onClick={(e) => {
              if (props.mode === 'multiple' || props.mode === 'tags')
                e.stopPropagation();
            }}
          >
            {menu}
          </div>
        )}
      ></Select>
      {allowClear && isSelected && (
        <CloseCircleFilled className="clear-icon" onClick={onClear} />
      )}
    </AppSelectContainer>
  );
};
