import { CalendarOutlined, CloseCircleFilled } from '@ant-design/icons';
import { Input, Popover, Space } from 'antd';
import dayjs from 'dayjs';
import { useEffect, useMemo, useRef, useState } from 'react';
import { DayPicker } from 'react-day-picker';
import { styled } from 'styled-components';

import { isDateInRange } from '../../utils/datetime';

import type { DateRange } from '../../interfaces';
import type { MouseEventHandler, ReactNode } from 'react';
import type {
  DateRange as DayPickerRange,
  SelectRangeEventHandler,
} from 'react-day-picker';
import type { CSSProperties } from 'styled-components';

import 'react-day-picker/dist/style.css';

interface AppDateRangePickerProps {
  value?: DateRange;
  format: string;
  onChange: (newValue?: DateRange) => void;
  renderFooter?: ReactNode;
  allowedRange?: DateRange;
  style?: CSSProperties;
}
const MIN_DATE = dayjs().subtract(100, 'year');
const MAX_DATE = dayjs().add(100, 'year');

const DateRangePicker = styled.div`
  .app-date-range-picker__clear {
    display: none;
  }

  .ant-input-affix-wrapper:hover {
    .app-date-range-picker__clear {
      display: block;
    }
  }

  .ant-input-group-addon {
    cursor: pointer;
  }
`;

const StyledDayPicker = styled(DayPicker)`
  --rdp-caption-font-size: 14px;
  --rdp-accent-color: #3371b2;
  --rdp-cell-size: 42px;
  --rdp-background-color: rgba(0, 0, 0, 0.04);

  margin: 2px 8px;

  .rdp-caption_label {
    font-weight: normal;
  }
  .rdp-nav_button > svg {
    width: 13px;
    height: 13px;
  }
  .rdp-cell,
  .rdp-head_cell {
    user-select: none;
  }

  .rdp-day_today:not(.rdp-day_outside) {
    font-weight: normal;
  }
`;

const AppDateRangePicker = ({
  value,
  format,
  onChange,
  allowedRange,
  renderFooter,
  style,
}: AppDateRangePickerProps) => {
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);

  const fromValue = useMemo(() => value && value[0]?.toDate(), [value]);
  const toValue = useMemo(() => value && value[1]?.toDate(), [value]);
  const selectedRange: DayPickerRange = useMemo(
    () => ({ from: fromValue, to: toValue }),
    [fromValue, toValue],
  );
  const disabledDates = useMemo(
    () =>
      allowedRange && [
        {
          from: MIN_DATE.toDate(),
          to: allowedRange[0]?.subtract(1, 'day').toDate(),
        },
        {
          from: allowedRange[1]?.add(1, 'day').toDate(),
          to: MAX_DATE.toDate(),
        },
      ],
    [allowedRange],
  );

  const controlRef = useRef(null);

  const onBodyClick = (e: Event) => {
    if (!controlRef.current) return;
    if (!(controlRef.current as Node).contains(e.target as Node))
      setIsCalendarOpen(false);
  };

  useEffect(() => {
    document.body.addEventListener('click', onBodyClick);
    return function cleanup() {
      window.removeEventListener('click', onBodyClick);
    };
  }, []);

  const today = useMemo(() => {
    const now = dayjs();
    if (!allowedRange || isDateInRange(now, allowedRange, 'day'))
      return new Date();
    return allowedRange[0]?.isAfter(now)
      ? allowedRange[0]?.toDate()
      : allowedRange[1]?.toDate();
  }, [allowedRange]);

  const handleRangeSelect: SelectRangeEventHandler = (
    range: DayPickerRange | undefined,
  ) => {
    onChange([
      range?.from && dayjs(range?.from),
      range?.to && dayjs(range?.to).hour(23).minute(59),
    ]);
  };

  const content = (
    <StyledDayPicker
      mode="range"
      selected={selectedRange}
      disabled={disabledDates}
      today={today}
      onSelect={handleRangeSelect}
      footer={renderFooter}
    />
  );

  const InputClearIcon = ({ onClick }: { onClick: MouseEventHandler }) => (
    <CloseCircleFilled
      className="app-date-range-picker__clear"
      onClick={onClick}
      style={{ color: 'rgba(0, 0, 0, 0.18)', marginRight: '-4px' }}
    />
  );

  const onFromClear: MouseEventHandler = (e) => {
    e.stopPropagation();
    onChange([undefined, value && value[1]]);
  };

  const onToClear: MouseEventHandler = (e) => {
    e.stopPropagation();
    onChange([value && value[0], undefined]);
  };

  return (
    <Popover
      content={content}
      open={isCalendarOpen}
      getPopupContainer={() => controlRef.current || document.body}
      style={{ width: '100%' }}
    >
      <DateRangePicker
        className="app-date-range-picker"
        ref={controlRef}
        onClick={() => {
          setIsCalendarOpen(true);
        }}
      >
        <Space.Compact style={style}>
          <Input
            className="app-date-range-picker__input"
            placeholder="Start date"
            value={
              selectedRange.from && dayjs(selectedRange.from).format(format)
            }
            readOnly
            style={{ width: 'calc(50% - 18px)' }}
            onFocus={() => setIsCalendarOpen(true)}
            suffix={
              selectedRange.from && <InputClearIcon onClick={onFromClear} />
            }
          />
          <Input
            className="app-date-range-picker__input"
            placeholder="End date"
            value={selectedRange.to && dayjs(selectedRange.to).format(format)}
            readOnly
            suffix={selectedRange.to && <InputClearIcon onClick={onToClear} />}
            addonAfter={
              <CalendarOutlined
                style={{ color: 'rgba(0, 0, 0, 0.25)' }}
                onClick={() => setIsCalendarOpen(!isCalendarOpen)}
              />
            }
            style={{ width: 'calc(50% + 19px)' }}
            onFocus={() => setIsCalendarOpen(true)}
          />
        </Space.Compact>
      </DateRangePicker>
    </Popover>
  );
};
export { AppDateRangePicker };
